
import { defineComponent } from "vue";
import installButton from "./InstallButton.vue";
import update from "../assets/js/update";
import { mapState } from "vuex";
import { default as ProgressBar } from "progressbar.js";
import { Collapse } from "bootstrap";
// import ProgressBar = require("progressbar.js");

export default defineComponent({
  name: "NavBar",
  components: { installButton },
  data: function () {
    return {
      progress: null,
      collapse: null,
    };
  },
  computed: {
    ...mapState(["progressing"]),
  },
  mounted: function () {
    this.progress = new ProgressBar.Line("#pgr_bar", {
      strokeWidth: 0.8,
      color: "#3f51b5",
      trailColor: "transparent",
      trailWidth: 0,
    });
    this.collapse = new Collapse(this.$refs.collapse, {
      toggle: false,
    });
  },
  watch: {
    progressing: function (val) {
      if (val) {
        this.progress.animate(600, { duration: 300000, easing: "linear" });
      } else {
        this.progress.animate(0, { duration: 10, easing: "linear" });
      }
    },
  },
});
