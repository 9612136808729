
import { defineComponent } from "vue";
export default defineComponent({
  name: "ToggleSwitch",
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
});
