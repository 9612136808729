import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["form-check", { 'form-check-inline': _ctx.inline }])
  }, [
    _withDirectives(_createElementVNode("input", {
      class: "form-check-input",
      type: "checkbox",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      id: _ctx.id
    }, null, 8, _hoisted_1), [
      [_vModelCheckbox, _ctx.value]
    ]),
    _createElementVNode("label", {
      class: _normalizeClass(["form-check-label", { inactive: !_ctx.modelValue }]),
      for: _ctx.id
    }, _toDisplayString(_ctx.label), 11, _hoisted_2)
  ], 2))
}