
import { defineComponent } from "vue";
import { mapState } from "vuex";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import AutoSuggest from "./AutoSuggest.vue";
import ToggleSwitch from "./ToggleSwitch.vue";
import CheckBox from "./CheckBox.vue";

require("flatpickr/dist/themes/dark.css");

export default defineComponent({
  name: "SearchForm",
  data: function () {
    return {
      // Get more from https://flatpickr.js.org/options/
      config: {
        enableTime: true,
        time_24hr: true,
        dateFormat: "d.m.Y H:i",
        altFormat: "d.m.Y H:i",
      },
      check_form_validity: false,
      start_valid: false,
      destination_valid: false,
    };
  },
  created() {
    this.$store.dispatch("fetch_stations");
  },
  methods: {
    get_connections() {
      if (this.start_valid && this.destination_valid) {
        this.$store.dispatch("fetch_stations").then(async () => {
          this.$router.push({
            path: "/connections",
            query: this.convert_values_to_string(this.search_params),
            // hash: "#content",
          });
          this.$store.dispatch("get_connections");
        });
      }
    },
    convert_values_to_string(object: any) {
      return Object.fromEntries(
        Object.entries(object).map(([k, v]) => [k, String(v)])
      );
    },
    swap_stations() {
      [this.search_params.start, this.search_params.destination] = [
        this.search_params.destination,
        this.search_params.start,
      ];
    },
  },
  computed: {
    ...mapState(["stations", "search_params"]),
  },
  components: {
    flatPickr,
    AutoSuggest,
    ToggleSwitch,
    CheckBox,
  },
});
