import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "toggle_container",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', !_ctx.modelValue)))
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(["form-check-label", { inactive: _ctx.modelValue }])
    }, "Ab", 2),
    _createElementVNode("span", {
      class: _normalizeClass(["toggler", { on: _ctx.modelValue }])
    }, null, 2),
    _createElementVNode("span", {
      class: _normalizeClass(["form-check-label", { inactive: !_ctx.modelValue }])
    }, "An", 2)
  ]))
}