
import { defineComponent } from "vue";
import { mapState } from "vuex";
import SnackBar from "../components/SnackBar.vue";

export default defineComponent({
  name: "ErrorDisplay",
  computed: {
    ...mapState(["error"]),
  },
  components: {
    SnackBar,
  },
});
