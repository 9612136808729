
import { defineComponent } from "vue";
export default defineComponent({
  name: "installButton",
  data: function () {
    return {
      showInstallButton: false,
      deferredPrompt: null,
    };
  },
  mounted: function () {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
      this.showInstallButton = true;
    });
  },
  methods: {
    install: function () {
      this.showInstallButton = false;
      this.deferredPrompt.prompt();
    },
  },
});
