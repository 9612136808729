import { vModelText as _vModelText, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "dropdown flex-fill" }
const _hoisted_2 = ["placeholder", "name"]
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      class: _normalizeClass(["form-control", { 'is-invalid': _ctx.show_error }]),
      type: "text",
      autocomplete: "off",
      placeholder: _ctx.placeholder,
      name: _ctx.name,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx._value) = $event)),
      onKeydown: [
        _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.enter && _ctx.enter(...args)), ["enter"])),
        _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.down && _ctx.down(...args)), ["down"])),
        _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.up && _ctx.up(...args)), ["up"])),
        _cache[4] || (_cache[4] = _withKeys(
//@ts-ignore
(...args) => (_ctx.tab && _ctx.tab(...args)), ["tab"])),
        _cache[5] || (_cache[5] = _withKeys(
//@ts-ignore
(...args) => (_ctx.loose_focus && _ctx.loose_focus(...args)), ["esc"]))
      ],
      onInput: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args))),
      onBlur: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.loose_focus && _ctx.loose_focus(...args)))
    }, null, 42, _hoisted_2), [
      [_vModelText, _ctx._value]
    ]),
    _createElementVNode("ul", {
      class: _normalizeClass(["dropdown-menu dropdown-menu-dark", { show: _ctx.open_suggestions }]),
      style: {"width":"100%"}
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.matches, (suggestion, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          onClick: ($event: any) => (_ctx.suggestion_click(index)),
          onMousedown: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.mousedown_prevent && _ctx.mousedown_prevent(...args)))
        }, [
          _createElementVNode("a", {
            class: _normalizeClass(["dropdown-item", { active: index === _ctx.current }])
          }, _toDisplayString(suggestion), 3)
        ], 40, _hoisted_3))
      }), 128))
    ], 2)
  ]))
}