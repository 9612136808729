
import { defineComponent } from "vue";
import NavBar from "./components/NavBar.vue";
import SearchForm from "./components/SearchForm.vue";
import update from "./assets/js/update";
import SnackBar from "./components/SnackBar.vue";
import ErrorDisplay from "./components/ErrorDisplay.vue";

export default defineComponent({
  name: "App",
  data: function () {
    return {
      connections: [],
    };
  },
  components: {
    SearchForm,
    SnackBar,
    NavBar,
    ErrorDisplay,
  },
  mixins: [update],
  mounted() {
    const styles = {
      light_grey: "color: #e5e5e5;",
      light_grey_bg_black:
        "color: #e5e5e5;background-color: #000;font-weight: bold;",
      light_grey_bg_red: "color: #e5e5e5;background-color: #b43836;",
      dark_grey: "color: #666666;",
    };
    console.log(
      "%c████████████████████████████████████▇▆▅▃▁\n" +
        "%c       Bahn-Vorhersage      ███████▙  ▜" +
        "%c██▆▁\n" +
        "%c███████████████████████████████████████████▃\n" +
        "%c▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀" +
        "%c█████▄" +
        "%c▖\n" +
        "%c█████████████████████████████████████████████\n" +
        "%c ▜█▀▀▜█▘                       ▜█▀▀▜█▘" +
        "%c   ▀▀▀",
      styles.light_grey,
      styles.light_grey_bg_black,
      styles.light_grey,
      styles.light_grey,
      styles.light_grey_bg_red,
      styles.light_grey,
      styles.dark_grey,
      styles.light_grey,
      styles.dark_grey,
      styles.light_grey
    );
  },
  methods: {},
});
