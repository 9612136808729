import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.showInstallButton)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.install && _ctx.install(...args))),
        class: "btn btn-primary"
      }, " App installieren "))
    : _createCommentVNode("", true)
}