import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "snackbar-center"
}
const _hoisted_2 = {
  id: "snackbar",
  class: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (_ctx.show)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", {
                class: _normalizeClass(["shadow rounded snack-content", [
            {
              'layout small': _ctx.layout === 'small',
              'layout multiline': _ctx.layout === 'multiline',
            },
            _ctx.style_class,
          ]])
              }, [
                _createElementVNode("div", null, [
                  _renderSlot(_ctx.$slots, "default")
                ]),
                (_ctx.timeout === -1)
                  ? _renderSlot(_ctx.$slots, "action", { key: 0 }, () => [
                      _createElementVNode("div", {
                        class: "click_text text-right",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.show = false))
                      }, " SCHLIESSEN ")
                    ])
                  : _createCommentVNode("", true)
              ], 2)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}