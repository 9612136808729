
import { defineComponent } from "vue";
export default defineComponent({
  name: "SnackBar",
  props: {
    layout: {
      type: String,
      default: "small",
      validator: function (value: any) {
        return ["small", "multiline"].indexOf(value) !== -1;
      },
    },
    timeout: {
      type: Number,
      default: -1,
    },
    style_class: {
      type: String,
      default: "",
    },
  },
  data: function () {
    return {
      show: true,
    };
  },
  updated() {
    if (this.timeout !== -1) {
      setTimeout(
        function () {
          this.show = false;
        }.bind(this),
        this.timeout
      );
    }
  },
  mounted() {
    if (this.timeout !== -1) {
      setTimeout(
        function () {
          this.show = false;
        }.bind(this),
        this.timeout
      );
    }
  },
});
